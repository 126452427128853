<template>
  <v-row>
    <v-dialog
      v-model="isDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      persistent
      scrollable
      width="70%"
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{
            Object.keys(isEdit).length == 0
              ? $t("extracuricular.dialog.extracurricular_add")
              : $t("extracuricular.dialog.extracurricular_update")
          }}
          <div style="position: absolute; right: 25px">
            <v-btn icon @click="closeDialog(false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="form" v-model="extraForm" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-autocomplete
                  v-model="data.extra"
                  :items="g_academic_extracurricular.extracurricular"
                  :label="$t('extracuricular.dialog.extracurricular_select')"
                  :rules="[v => !!v || $t('app.required')]"
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="data.description"
                  :label="$t('extracuricular.description')"
                  dense
                  auto-grow
                  rows="1"
                  outlined
                />
              </v-col>

              <v-col cols="12">
                <v-card v-for="(d, i) in data.desc" :key="i">
                  <v-row class="pa-6 mb-6">
                    <v-col cols="12" sm="12">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            v-model="d.day"
                            :items="hari"
                            :label="$t('extracuricular.dialog.select_day')"
                            :rules="[v => !!v || $t('app.required')]"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <!-- <v-select
                            v-model="d.place"
                            :items="g_academic_extracurricular.masterPlace"
                            :label="$t('extracuricular.dialog.select_place')"
                            :rules="[v => !!v || $t('app.required')]"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                          /> -->
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" sm="9">
                          <v-row>
                            <v-col cols="12" sm="6">
                              <TimePicker
                                :setValue="d.start ? d.start.substr(0, 5) : ''"
                                :label="
                                  $t('extracuricular.dialog.select_start_time')
                                "
                                outlined
                                dense
                                @value="v => (d.start = v)"
                              />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <TimePicker
                                :min="d.start"
                                :isDisabled="!d.start"
                                :setValue="d.end ? d.end.substr(0, 5) : ''"
                                :label="
                                  $t('extracuricular.dialog.select_end_time')
                                "
                                outlined
                                dense
                                @value="v => (d.end = v)"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <span class="font-weight-bold">{{
                            computedTime(d, i)
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="8" sm="10">
                          <v-autocomplete
                            v-model="d.teachers"
                            :items="g_academic_extracurricular.listTeacher"
                            :label="$t('extracuricular.dialog.select_teacher')"
                            :rules="[v => !!v || $t('app.required')]"
                            item-value="id"
                            item-text="name"
                            multiple
                            deletable-chips
                            outlined
                            dense
                            return-object
                          >
                            <template v-slot:selection="{ index, item }">
                              <span v-if="index < 2">
                                <v-chip small label color="primary">{{
                                  item.name
                                }}</v-chip>
                              </span>
                              <span v-if="index === 2" class="grey--text ml-2">
                                (+{{ d.teachers.length - 2 }}
                                {{ $t("app.others") }})
                              </span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4" sm="2">
                          <div
                            :class="$vuetify.breakpoint.smAndDown && 'mb-5'"
                            style="text-align: right"
                          >
                            <v-icon
                              v-if="descMethod(i, 'delete')"
                              dark
                              color="error"
                              @click="descHandler(i)"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col
                v-if="data.desc.length < 4"
                cols="12"
                class="justify-end mb-5 d-flex"
              >
                <v-btn
                  class="gradient-primary"
                  dark
                  depressed
                  @click="descHandler(null)"
                >
                  <v-icon class="mr-2">mdi-plus-box</v-icon
                  >{{ $t("extracuricular.dialog.add_new_schedule") }}</v-btn
                >
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="data.tiers"
                      :items="g_academic_extracurricular.masterGrade"
                      :label="$t('extracuricular.dialog.class')"
                      :rules="[v => !!v || $t('app.required')]"
                      item-value="id"
                      item-text="grade"
                      multiple
                      deletable-chips
                      small-chips
                      outlined
                      dense
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip small label color="primary">{{
                          item.grade
                        }}</v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="data.tuition"
                      :label="$t('extracuricular.dialog.tuition')"
                      required
                      outlined
                      type="number"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              class="mr-2"
              outlined
              color="primary"
              @click="closeDialog(false)"
              >{{ $t("app.back") }}</v-btn
            >
            <v-btn
              :loading="loading"
              class="gradient-primary"
              dark
              depressed
              @click="submit"
              >{{ $t("app.save") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import TimePicker from "@/components/TimePicker";
import moment from "moment/moment";
moment.locale("id");
import { addExtra, updateExtra } from "@/api/admin/academic/extracurricular";

export default {
  props: {
    isDialog: {
      type: Boolean,
      required: true
    },
    isEdit: Object
  },
  watch: {
    isEdit(data) {
      if (Object.keys(data).length != 0) {
        this.$nextTick(() => {
          this.data = data;
        });
      }
    }
  },
  components: {
    TimePicker
  },
  computed: mapGetters(["g_master_data_siswa", "g_academic_extracurricular"]),
  data() {
    return {
      modalStart: false,
      modalEnd: false,
      extraForm: false,
      hasTuition: false,
      totalTime: 0,
      data: {
        extra: "",
        description: "",
        desc: [
          {
            start: "",
            end: "",
            day: "",
            place: "",
            teachers: [],
            total_time: 0
          }
        ],
        tuition: 0,
        tiers: []
      },
      loading: false,
      tableHeaders: [
        "#",
        this.$i18n.t("extracuricular.dialog.extracurricular_available"),
        this.$i18n.t("master_data.student.action")
      ],
      tableValues: [
        { id: "1", value: "Nilai Agama Kognitif Seni" },
        { id: "2", value: "Berhitung Bernyanyi Mewarnai" }
      ],
      hari: [
        { id: 1, name: "Minggu" },
        { id: 2, name: "Senin" },
        { id: 3, name: "Selasa" },
        { id: 4, name: "Rabu" },
        { id: 5, name: "Kamis" },
        { id: 6, name: "Jumat" },
        { id: 7, name: "Sabtu" }
      ]
    };
  },
  methods: {
    closeDialog(flag) {
      flag ? this.$emit("save") : this.$emit("cancel");
      this.reset();
    },
    descMethod(i, del) {
      const des = this.data.desc;
      if (del) {
        if (i == 6) return i != 0;
        return des.length > 0 && des.length != 1;
      } else {
        return des.length < 4 && i == des.length - 1;
      }
    },
    computedTime(d, i) {
      const start = d.start && moment(d.start, "HH:mm");
      const end = d.end && moment(d.end, "HH:mm");

      if (start && end) {
        const minute = moment.duration(end.diff(start)).asMinutes();
        this.data.desc[i].total_time = minute;
        return `${minute} menit`;
      }
    },
    descHandler(index) {
      if (index == null) {
        if (this.data.desc.length < 7) {
          this.data.desc.push({
            start: "",
            end: "",
            day: "",
            place: "",
            teachers: [],
            total_time: 0
          });
        }
      } else {
        this.data.desc.splice(index, 1);
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (Object.keys(this.isEdit).length != 0) {
          updateExtra(this.isEdit)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.closeDialog(true);
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loading = false;
            })
            .catch(() => (this.loading = false));
        } else {
          addExtra(this.data)
            .then(res => {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.code
                  ? this.$i18n.t("app.success_save")
                  : res.data.message,
                color: res.data.code ? "success" : "error"
              });
              this.loading = false;
              if (res.data.code) this.closeDialog(true);
            })
            .catch(() => (this.loading = false));
        }
      }
    },
    reset() {
      this.data.desc = [
        { start: "", end: "", day: "", place: "", teachers: [], total_time: 0 }
      ];
      this.data.extra = "";
      this.data.description = "";
      this.$refs.form.reset();
    }
  }
};
</script>
